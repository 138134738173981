<template>
  <div>
    <b-row class="justify-content-md-center mt-7">
      <b-col cols="3" class="text-center">
        <b-form-input
          id="filter-input"
          v-model="filter"
          type="search"
          class="w-75 mx-auto"
          :placeholder="$t(`admin.form.${field.id}.placeholder`)"
        ></b-form-input>
      </b-col>
      <b-col cols="3" class="text-center">
        <b-button class="btn" @click="selectAll('CONTROLLER')">
          {{ $t("admin.form.select_all_controller") }}
        </b-button>
      </b-col>
      <b-col cols="3" class="text-center">
        <b-button class="btn" @click="selectAll('ADMIN')">
          {{ $t("admin.form.select_all_admin") }}
        </b-button>
      </b-col>
    </b-row>
    <b-row class="justify-content-md-center mt-5">
      <b-col cols="9">
        <b-table
          class="w-100"
          :items="filtered"
          :fields="field.fields || []"
          :current-page="actualPage"
          :per-page="field.perPage || 5"
        >
          <template #cell(controller)="row">
            <b-form-checkbox
              :value="row.item.id"
              v-model="value['CONTROLLER']"
              switch
            />
          </template>
          <template #cell(admin)="row">
            <b-form-checkbox
              v-model="value['ADMIN']"
              :value="row.item.id"
              switch
            />
          </template>
        </b-table>
      </b-col>
      <b-col cols="9" class="text-center mt-3">
        <b-pagination
          v-model="actualPage"
          :total-rows="filtered.length"
          :per-page="field.perPage || 5"
          align="fill"
          size="md"
          class="ml-auto mr-auto w-50"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "TableForm",
  props: {
    field: {
      required: true
    },
    value: {
      required: true
    }
  },
  data() {
    return {
      actualPage: 1,
      filter: ""
    };
  },
  mounted() {
    this.rows = this.field.options;
  },
  methods: {
    selectAll(type) {
      if (this.value[type].length < this.field.options.length) {
        this.value[type] = _.map(this.field.options, "id");
      } else {
        this.value[type] = [];
      }
    }
  },
  computed: {
    filtered() {
      let result = this.field.options;
      if (this.filter !== "") {
        const regex = new RegExp(`\\b${_.deburr(this.filter)}`, "i");
        result = _.filter(result, company => {
          return regex.test(_.deburr(company.name));
        });
      }
      return result;
    }
  }
};
</script>
