<template>
  <b-form class="form" @submit.stop.prevent="submitForm" v-if="loaded">
    <b-row>
      <b-col
        :md="field.size || '6'"
        sm="12"
        v-for="field in fields"
        :key="field.id"
      >
        <b-form-group
          style="position: relative"
          :state="hasError(field, true)"
          :invalid-feedback="hasError(field)"
          :label="
            field.hidden || field.type === 'radio' || field.condition === false
              ? ''
              : $t(`admin.form.${field.id}.label`) +
                ` ${field.required ? '*' : ''}`
          "
          :label-for="field.id"
          :description="$t(`admin.form.${field.id}.description`)"
        >
          <div v-if="field.type === 'select'" class="d-flex align-items-center">
            <b-form-checkbox
              v-if="field.multiple"
              :value="{ [field.id]: true }"
              :unchecked-value="{ [field.id]: false }"
              :name="`check-${field.id}`"
              @change="calculateChecked"
            >
            </b-form-checkbox>
            <div class="d-flex w-100 flex-column">
              <multiselect
                :allowEmpty="!field.required"
                :readonly="field.readonly"
                v-model="form[field.id]"
                :id="field.id"
                @select="selected"
                :required="field.required"
                :options="
                  field.options
                    ? field.options.length > 0
                      ? field.options
                      : []
                    : []
                "
                :searchable="true"
                :label="field.label || ''"
                :close-on-select="true"
                :show-labels="true"
                :placeholder="$t(`admin.form.${field.id}.placeholder`)"
              />
              <div
                v-if="
                  field.servicesAsociated && field.servicesAsociated.length > 0
                "
              >
                <b>{{ $t(`admin.form.${field.id}.asociated`) }}</b>
                <span>
                  {{
                    field.servicesAsociated
                      .map(service => service.name)
                      .join(", ")
                  }}
                </span>
              </div>
            </div>
          </div>
          <div
            v-else-if="field.type === 'multiselect'"
            class="d-flex align-items-center"
          >
            <b-form-checkbox
              v-if="field.multiple"
              :value="{ [field.id]: true }"
              :unchecked-value="{ [field.id]: false }"
              :name="`check-${field.id}`"
              @change="calculateChecked"
            >
            </b-form-checkbox>
            <multiselect
              :allowEmpty="true"
              :readonly="field.readonly"
              :id="field.id"
              v-model="form[field.id]"
              :trackBy="field.trackBy"
              @select="selected"
              :options="
                field.options
                  ? field.options.length > 0
                    ? field.options
                    : []
                  : []
              "
              :required="field.required"
              :searchable="true"
              :multiple="true"
              :selectLabel="$t(`admin.form.default.select`)"
              :show-labels="true"
              :label="field.label || ''"
              :deselectLabel="$t(`admin.form.default.deselect`)"
              :selectedLabel="$t(`admin.form.default.selected`)"
              :placeholder="$t(`admin.form.${field.id}.placeholder`)"
            >
              <template slot="selection" slot-scope="{ values }"
                ><span class="multiselect__single" v-if="values.length > 3"
                  >{{ values.length }} options selected</span
                ></template
              >
            </multiselect>
          </div>
          <div
            v-else-if="field.type === 'boolean'"
            class="d-flex justify-content-center"
          >
            <b-form-checkbox
              v-if="field.multiple"
              :value="{ [field.id]: true }"
              :unchecked-value="{ [field.id]: false }"
              :name="`check-${field.id}`"
              @change="calculateChecked"
            >
            </b-form-checkbox>
            <toggle-button
              class="select d-flex align-items-center"
              :readonly="field.readonly"
              name="notifications"
              v-model="form[field.id]"
              :value="form[field.id]"
              :required="field.required"
              :width="field.width"
              :labels="{
                checked: field.options.checked,
                unchecked: field.options.unchecked
              }"
            />
          </div>
          <b-form-file
            v-else-if="field.type === 'file'"
            :readonly="field.readonly"
            :disabled="field.condition"
            v-model="form[field.id]"
            no-drop
            accept="image/*"
            :value="form[field.id]"
            :required="field.required"
          ></b-form-file>
          <div v-else-if="field.type === 'button' && field.condition">
            <b-button
              variant="secondary"
              size="md"
              class="font-weight-bolder"
              @click="showSCIMCode = !showSCIMCode"
            >
              {{ $t("assets.buttons.scim_code") }}
            </b-button>
            <b-modal
              hide-header
              centered
              scrollable
              v-model="showSCIMCode"
              size="lg"
            >
              <p>
                {{ field.token }}
              </p>
              <template #modal-footer="{ ok }">
                <b-button size="sm" variant="success" @click="ok()">
                  OK
                </b-button>
              </template>
            </b-modal>
          </div>
          <div
            v-else-if="field.type === 'saml_configuration' && field.condition"
          >
            <div>
              <b-row style="align-items: end;">
                <b-col md="6" sm="12">
                  <b-button
                    variant="primary"
                    size="md"
                    class="font-weight-bolder"
                    @click="downloadFile(field.url)"
                  >
                    {{ $t("admin.form.download_metadata") }}
                  </b-button>
                </b-col>
                <b-col md="6" sm="12">
                  <b-form-input
                    autocomplete="off"
                    :id="field.id"
                    :required="field.required"
                    v-model="form[field.id]"
                    :placeholder="$t(`admin.form.${field.id}.placeholder`)"
                    :hidden="field.hidden ? true : false"
                    :aria-describedby="`${field.id}-feedback`"
                    :state="
                      hasError(field, true) === false ? false : field.validation
                    "
                  >
                  </b-form-input>
                </b-col>
              </b-row>
            </div>
          </div>
          <div
            v-else-if="field.type === 'text'"
            class="select d-flex align-items-center"
          >
            <b-form-checkbox
              v-if="field.multiple"
              :value="{ [field.id]: true }"
              :unchecked-value="{ [field.id]: false }"
              :name="`check-${field.id}`"
              @change="calculateChecked"
            >
            </b-form-checkbox>
            <b-form-input
              @change="change(field.id)"
              :readonly="field.readonly"
              :disabled="field.disabled"
              :autocomplete="field.autocomplete ? 'on' : 'off'"
              :id="field.id"
              @input="
                value =>
                  (form[field.id] = field.uppercase
                    ? form[field.id].toUpperCase()
                    : form[field.id])
              "
              :required="field.required"
              v-model="form[field.id]"
              :placeholder="$t(`admin.form.${field.id}.placeholder`)"
              :hidden="field.hidden ? true : false"
              :aria-describedby="`${field.id}-feedback`"
              :state="
                hasError(field, true) === false
                  ? false
                  : validIsEmpty(form[field.id])
                  ? null
                  : field.maxLength
                  ? maxLengthError(form[field.id], field.maxLength)
                  : field.validation
              "
            >
            </b-form-input>
          </div>
          <div
            v-else-if="field.type === 'profiles_table'"
            class="select align-items-center w-100"
          >
            <table-form :field="field" :value="form[field.id]"></table-form>
          </div>
          <div v-if="field.info">
            <i
              class="mt-2 fa fa-info-circle text-info"
              v-b-tooltip.hover
              :title="$t(`admin.form.${field.id}.info`)"
            ></i>
          </div>
          <b-form-invalid-feedback
            :id="`${field.id}-feedback`"
            v-if="hasError(field, true) !== false"
          >
            <span
              v-if="
                field.maxLength &&
                  !maxLengthError(form[field.id], field.maxLength)
              "
            >
              {{ callgetError("max_length", { length: field.maxLength }) }}
            </span>
            <span v-else>
              {{ callgetError("format") }}
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="mt-4">
        <b-button
          type="submit"
          variant="primary"
          size="lg"
          class="font-weight-bolder"
          :disabled="isSubmitting"
        >
          {{ $t("admin.form.save") }}
        </b-button>
      </b-col>
      <b-col cols="12" class="mt-4">
        <b-alert
          :show="error !== false"
          variant="danger"
          v-if="error !== false"
        >
          {{ callgetError(error) }}
        </b-alert>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import _ from "lodash";
import TableForm from "@/components/form/TableForm.component.vue";
import Multiselect from "vue-multiselect";
import { ToggleButton } from "vue-js-toggle-button";
import { validMaxLength } from "@/utils/validators";

import { getError } from "@/utils/helpers";

export default {
  name: "BasicForm",
  components: {
    Multiselect,
    ToggleButton,
    TableForm
  },
  data() {
    return {
      showSCIMCode: false,
      checked: [],
      loaded: false
    };
  },
  props: {
    save: {
      type: Function,
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    error: {
      required: false,
      default: false
    },
    errors: {
      required: false,
      type: Array
    },
    isSubmitting: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    callGetError(code, params) {
      return getError(code, params);
    },
    submitForm() {
      this.save(this.form, this.checked);
    },
    downloadFile(url) {
      this.axios
        .get(url)
        .then(({ data }) => {
          const downloadUrl = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          const name = `metadata_Kymatio_${
            JSON.parse(localStorage.getItem("companySelected")).name
          }.xml`;
          link.href = downloadUrl;
          link.setAttribute("download", name);
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch(error => {
          if (error.response.status === 412) {
            this.$swal(this.callGetError("file_not_found"), "", "error");
          }
        });
    },
    callgetError(code, params = {}) {
      return getError(code, params);
    },
    calculateChecked(value) {
      this.checked[Object.keys(value)[0]] = Object.values(value)[0];
    },
    selected(selectedObject, id) {
      let field = _.find(this.fields, o => id === o.id);
      if (field?.select) return field.select(selectedObject);
      else return false;
    },
    change(id) {
      let field = _.find(this.fields, o => id === o.id);
      return field.change ? field.change() : false;
    },
    validIsEmpty(text) {
      return _.isEmpty(text);
    },
    maxLengthError(value, length) {
      return validMaxLength(value, length);
    },
    hasError(field, boolean = false) {
      let result = null;
      const finder = _.find(this.errors, error => error.name === field.id);
      const found = !_.isEmpty(finder);
      if (found) {
        if (boolean) result = false;
        else if (!finder.error) {
          result = this.callgetError(finder.code);
        }
      }

      return result;
    }
  },
  mounted() {
    _.each(this.fields, field => {
      this.checked[field.id] = field.checked;
    });
    this.loaded = true;
  },
  watch: {
    form: {
      deep: true,
      handler(value) {
        this.$emit("updatedForm", value);
      }
    }
  }
};
</script>
